<template>
    <div class="Banner">
        <el-carousel trigger="click" height="720px">
            <el-carousel-item v-for="(item,key) in bannerList" :key="key">
                <img class="carousel-item" :src="item.banner_img" alt="" >
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    components: {},
    props: {
        bannerList: Array
    },
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {}
}
</script>

<style scoped lang="scss">
.Banner {
    position: absolute;
    top: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 720px;
    .carousel-item {
        width: 100%;
        height: 100%;
    }
}
::v-deep .el-carousel__indicators--horizontal .el-carousel__indicator {
    .el-carousel__button {
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }
}
</style>
