<template>
    <el-scrollbar class="index-container-yyt" ref="scrollbar" style="height: 100%;" :native="false">
        <div class="nav-banner">
            <nav-bar :is-hidden="isHidden" />
            <div class="banner-box" ref="banner">
                <banner :banner-list="bannerLists"  />
            </div>
        </div>
        <company-business />
        <company-info />
        <news-center :news-list="newsLists" />
        <bottom-footer></bottom-footer>
    </el-scrollbar>
</template>

<script>
import NavBar from "@/components/index/NavBar";
import Banner from "@/components/index/Banner";
import CompanyBusiness from "@/components/index/CompanyBusiness";
import CompanyInfo from "@/components/index/CompanyInfo";
import NewsCenter from "@/components/index/NewsCenter";
import Footer from "@/components/index/Footer";
import {banners, news} from "@/utils/apis";

export default {
    name: "Index",
    components: {NewsCenter, CompanyBusiness, NavBar,Banner, CompanyInfo, "bottom-footer": Footer},
    data() {
        return {
            bannerLists: [],
            newsLists: [],
            isHidden: false
        }
    },
    created() {
        this.bannerList()
        this.newsList()
    },
    mounted() {
        this.handleScroll()
    },
    methods: {
        handleScroll() {
            const scrollbarEle = this.$refs.scrollbar.wrap
            const bannerEle = this.$refs.banner
            scrollbarEle.onscroll = () => {
                const top = bannerEle.getBoundingClientRect().top
                if (top < -90) {
                    this.isHidden = true
                } else {
                    this.isHidden = false
                }
            }
        },
        async bannerList() {
            const result = await banners()
            if (result.code === 200) {
                this.bannerLists = result.data;
            }
        },
        async newsList() {
            const param = {
                delivery: 1,
                limit: 4,
                page: 1
            }
            const result = await news(param)
            if (result.code === 200) {
                this.newsLists = result.data;
            }
        }

    }
}
</script>

<style scoped lang="scss">
.index-container-yyt {
    &.el-scrollbar {
        :deep(.el-scrollbar__wrap) {
            overflow-x: hidden;
        }
    }
    .nav-banner {
        position: relative;
        height: 720px;
    }
}

</style>