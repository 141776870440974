<template>
    <div class="new-content">
        <div class="new-main">
          <div class="new-title">
              <div class="text">新闻中心</div>
              <div class="eng">NEWS CENTER</div>
          </div>
          <div class="new-list">
              <router-link v-for="(item, index) in newsList" :to="`/qualityCourse/newsCenterDetail?newsId=`+item.id" class="new-item" :key="`newList_${index}`">
                  <div class="bg" :style="{backgroundImage: `url(${item.cover})`}"></div>
                  <div class="desc">{{item.title}}</div>
                  <div class="operate">
                      <div class="time">{{item.create_time}}</div>
                      <i class="el-icon-arrow-right"></i>
                  </div>
              </router-link>
          </div>
          <router-link to="" class="more-btn">
              READ MODE<i class="el-icon-caret-right"></i>
          </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        newsList: Array
    },
    data() {
        return {
        }
    }
}
</script>

<style scoped lang="scss">
.new-content {
  padding: 60px 0 100px;
  background: #F6F7FB;
  .new-main {
    width: 1224px;
    margin: 0 auto;
  }
  .new-title {
    text-align: center;
    font-weight: bold;
    .text {
      font-size: 40px;
      color: #000;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(50% - 190px);
        width: 80px;
        height: 2px;
        background: #000;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: calc(50% - 190px);
        width: 80px;
        height: 2px;
        background: #000;
      }
    }
    .eng {
      font-size: 36px;
      color: #D9D9D9;
      font-family: PingFang SC-Bold, PingFang SC;
    }
  }
  .new-list {
    margin-top: 60px;
    display: flex;
    .new-item {
      width: 290px;
      height: 214px;
      background-color: #fff;
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      transition: all .3s;
      padding: 20px 24px;
      box-sizing: border-box;
      font-weight: 500;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
      & + .new-item {
        margin-left: 20px;
      }
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        display: none;
        transition: all .01s;
        z-index: 0;
        &:after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .25);
        }
      }
      .desc {
        font-size: 18px;
        color: #333;
        position: relative;
      }
      .operate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        left: 24px;
        bottom: 20px;
        right: 24px;
        color: #333;
      }
      &:hover {
        //top: -12px;
        animation: toTop .5s forwards 1 ease;
        .desc, .operate {
          color: #fff;
        }
        .bg {
          display: block;
        }
      }
    }
  }
  .more-btn {
    margin: 56px auto 0;
    width: 154px;
    height: 42px;
    border-radius: 21px;
    border: 1px solid #00556A;
    color: #00556A;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
}

@keyframes toTop {
  from {
    top: 0;
  }
  to {
    top: -12px;
  }
}
@-webkit-keyframes toTop {
  from {
    top: 0;
  }
  to {
    top: -12px;
  }
}
</style>