<template>
  <div class="company-business">
    <div class="title-CN">—— 公司业务 ——</div>
    <div class="title-EN">BUSINESS</div>
    <div class="banner-box">
      <div class="banner-item" v-for="(item, index) in companyBusiness" :key="index">
        <div class="item-icon">
          <i class="iconfont" style="font-size: 66px" v-html="item.icon"></i>
        </div>
        <div class="item-text">
          <div class="text-title">{{ item.title }}</div>
          <div>{{ item.textOne }}</div>
          <div>{{ item.textTwo }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyBusiness",
  data() {
    return {
      companyBusiness: [
        { icon: "&#xe6bb;", title: "供应链基础服务", textOne: "整合、共享、协同、创新，", textTwo: "助力企业创造更强核心竞争力" },
        { icon: "&#xe6b9;", title: "产业供应链", textOne: "实现全球化采购与销售", textTwo: " 把供应链红旗插遍全中国" },
        { icon: "&#xe6b8;", title: "流通消费", textOne: "构建扁平化渠道", textTwo: "引领中国流通业创新变革" },
        { icon: "&#xe6ba;", title: "数字营销", textOne: "整合、共享、协同、创新，", textTwo: "助力企业创造更强核心竞争力" },
        { icon: "&#xe6ad;", title: "供应链科技产业园", textOne: "“一平台、二基地、三中心”", textTwo: "现代化多功能综合性基地" },
        { icon: "&#xe6b3;", title: "创新产品中心", textOne: "以“供应链+”助力", textTwo: "新技术、新产品加速推向市场" },
      ],
      currentIndex: 1,
    };
  },
};
</script>

<style scoped>
.company-business {
  padding: 100px 0;
  font-family: PingFang SC-Bold, PingFang SC;
  color: #000;
  display: flex;
  background: #f5f7fa;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.title-CN {
  font-size: 40px;
  font-weight: bold;
}
.title-EN {
  margin-top: 8px;
  font-size: 36px;
  color: #d9d9d9;
  font-weight: bold;
}
.banner-box {
  width: 1224px;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 606px);
  grid-gap: 20px 12px;
  grid-template-rows: repeat(3, 186px);
}
.banner-item {
  background: #fff;
  border-radius: 8px;
  display: flex;
  padding: 38px 68px;
  color: #000;
}
.banner-item:hover {
  color: #fff;
  margin-bottom: 10px;
}
.banner-item:nth-child(1):hover {
  background-image: url(../../assets/index/corporate_business_banner_1.png);
}
.banner-item:nth-child(2):hover {
  background-image: url(../../assets/index/corporate_business_banner_2.png);
}
.banner-item:nth-child(3):hover {
  background-image: url(../../assets/index/corporate_business_banner_3.png);
}
.banner-item:nth-child(4):hover {
  background-image: url(../../assets/index/corporate_business_banner_4.png);
}
.banner-item:nth-child(5):hover {
  background-image: url(../../assets/index/corporate_business_banner_5.png);
}
.banner-item:nth-child(6):hover {
  background-image: url(../../assets/index/corporate_business_banner_6.png);
}
.item-icon {
  margin: 20px 68px 0 0;
  width: 66px;
  height: 66px;
  border: 1px dashed #d6d6d6;
}
.item-text {
  font-size: 14px;
  line-height: 24px;
}
.text-title {
  font-size: 20px;
  margin-bottom: 32px;
}
</style>
