<template>
    <div class="info_box">
        <div class="info_two_box">
            <div class="introduction_title">
                <p class="border"></p>
                <span class="text">公司简介</span>
                <p class="border"></p>
            </div>
            <div class="introduction_two_title">ABOUS US</div>
            <div class="introduce_box">
                <div class="introduce_left">
                    <img src="@/assets/index/introduce.png" alt="">
                </div>
                <div class="introduce_right">
                    <span class="introduce_right_width1">深圳市怡亚通供应链股份有限公司</span>
                    <span class="introduce_right_width2">深圳市怡亚通供应链股份有限公司是以物流为基础，以扁平化、共享化、去中心化的1+n供应链平台为载体（全面覆盖、全域营销、全程服务），以品牌运营、产业运营为核心，帮助客户实现市场份额保量、增量，从而提升客户核心竞争力的整合型运营服务商，成立于1997年，是世界500强——深圳市投资控股有限公司旗下企业，中国首家上市供应链公司（股票代码002183），在职员工近万人，2021年业务量超1000亿元，2022《财富》中国500强排名193位。</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompanyInfo"
}
</script>

<style scoped lang="scss">
    .info_box {
        width: 100%;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        padding: 64px 0;

        .info_two_box {
            width: 1224px;
            background-color: #00556A;
            border-radius: 8px;

            .introduction_title {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 55px;

                .text {
                    font-size: 40px;
                    color: #fff;
                    padding: 0 30px
                }

                .border {
                    width: 80px;
                    height: 2px;
                    background: #FFFFFF;
                    opacity: 1;
                }
            }
            .introduction_two_title {
                font-size: 36px;
                color: #F5F7FA;
                text-align: center;
                margin-top: 20px;
            }
            .introduce_box {
                display: flex;
                /*justify-content: space-between;*/
                margin-top: 65px;
                margin-bottom: 103px;

                .introduce_left {
                    margin-left: -72px;
                }

                .introduce_right {
                    min-width: 567px;
                    font-size: 16px;
                    color: #FFFFFF;
                    padding: 0 48px;

                    .introduce_right_width1 {
                        display: block;
                        font-size: 28px;
                        color: #FFFFFF;
                        margin-top: 13px;
                    }
                    .introduce_right_width2 {
                        display: block;
                        font-size: 16px;
                        color: #FFFFFF;
                        margin-top: 45px;
                    }
                }
            }
        }
    }
</style>