<template>
    <div class="footer">
        <div class="footer-content">
            <span class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备15019684号</a> </span>
            <span>Copyright - © 2022 厦门一课信息技术服务有限公司 版权所有</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped lang="scss">
.footer {
    width: 100%;
    height: 83px;
    background-color: rgba(51, 51, 51, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-content {
        span {
            &.beian {
                margin-right: 24px;
            }
            font-size: 14px;
            color: rgba(255, 255, 255, 1)
        }
    }
}
</style>