<template>
  <div class="nav-container" :class="{'nav-fix': isHidden}">
      <div class="nav-content">
          <div class="nav-logo"></div>
          <div class="nav-link">
                <router-link class="a-link" :to="item.path" v-for="(item, index) in navList" :key="index">
                    {{item.name}}
                </router-link>
          </div>
          <div class="nav-btn">
                <el-dropdown @command="handleCommand" class="backend-btn">
                    <span class="el-dropdown-link">
                        后台登录<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="teacher">教师登录</el-dropdown-item>
                        <el-dropdown-item command="student">学生登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
          </div>
      </div>

  </div>
</template>

<script>
export default {
  name: "NavBar",
    props: {
      isHidden: Boolean
    },
  data() {
    return {
      navList: [
          { name: "首页", path: "/" },
          { name: "公开课", path: "/qualityCourse/home/PublicClass" },
          { name: "联系我们", path: "/qualityCourse/contactUs" },
          { name: "新闻中心", path: "/qualityCourse/newsCenter" }
      ],
    };
  },
    methods: {
        handleCommand(command) {
            if (command === 'teacher') {
                this.$router.push('/user/teacherLogin')
                return
            }
            if (command === 'student') {
                this.$router.push('/user/login')
                return
            }
        }
    }
};
</script>

<style scoped lang="scss">
.nav-container {
    transition: all .5s;
    &.nav-fix {
        position: fixed;
        background-color: #FFFFFF;
        .nav-content {
            .nav-logo {
                background-image: url("../../assets/images/yiyatong_logo.png");
            }
        }
        .nav-link {
            .a-link {
                color: rgba(12, 26, 30, 1);
            }
        }
        .nav-btn {
            :deep(.backend-btn) {
                .el-dropdown-link {
                    cursor: pointer;
                    color: rgba(12, 26, 30, 1);
                    font-size: 18px;
                }
            }
        }
    }
    position: absolute;
    top: 0px;
    z-index: 999;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    .nav-content {
        width: 1224px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nav-logo {
            width: 195px;
            height: 56px;
            background-image: url("../../assets/images/yyttong_bg_fff.png");
            background-size: cover;
        }
    }
    .nav-link {
        .a-link {
            cursor: pointer;
            font-size: 18px;
            color: #FFFFFF;
            margin-left: 114px;
            &:first-child {
                margin-left: 0px;
            }
        }
    }
    .nav-btn {
        :deep(.backend-btn) {
            .el-dropdown-link {
                cursor: pointer;
                color: #FFFFFF;
                font-size: 18px;
            }
        }
    }
}
</style>
